<script setup>
import Layout from '@/Layouts/Guest.vue';
import {Head, router, usePage} from '@inertiajs/vue3';
import {ref, computed} from 'vue';

const name = ref('');
const password = ref('');
const remember = ref(false);
const loading = ref(false);
const errors = ref(null);

const page = usePage();
const message = computed(() => page.props.flash.message);

function submit() {
    errors.value = null;
    loading.value = true;
    router.post(route('dashboard.login'), {
        name: name.value,
        password: password.value,
        remember: remember.value
    }, {
        onError(responseErrors) {
            errors.value = responseErrors;
        },
        onFinish() {
            loading.value = false;
        }
    });
}
</script>

<template>
    <Head title="Вход"/>

    <Layout>
        <Head title="Log in"/>
        <v-sheet
            width="300"
            class="mx-auto text-center"
        >
            <v-text-field
                hide-details
                class="mb-4"
                v-model="name"
                label="Логин"
                @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
                hide-details
                class="mb-4"
                v-model="password"
                label="Пароль"
                type="password"
                @keyup.enter="submit"
            ></v-text-field>
            <v-checkbox
                hide-details
                v-model="remember"
                label="Запомнить меня"
            ></v-checkbox>
            <v-alert
                v-if="errors"
                color="error"
                class="mb-4"
            >
                <div v-for="error in errors">{{ error }}</div>
            </v-alert>
            <v-alert
                v-if="message"
                class="mb-4"
                :color="'secondary'"
            >
                {{ message }}
            </v-alert>
            <v-btn
                class="my-2"
                @click="submit"
                :loading="loading"
            >
                Войти
            </v-btn>
        </v-sheet>
    </Layout>
</template>
